import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import logofullImg from "../../images/projectsfull/entityonelogofull.gif";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>EntityOne logo animation</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="EntityOne logo animation"
              projectfulldescription="When redesigning EntityOne’s new website we wanted to not only upgrade the visual style but also create some extra’s to make the new site even more modern. I decided to create this logo animation for the new site."
              projectclient="EntityOne"
              projectrole="Motion Design"
              projectdate="2019"
              projectfullimg={<div className="project--img--inner"><img data-src={logofullImg} className="project--img lazyload" alt="EntityOne logo animation" /></div>}
              projectnextlink="/projects/projectworkflow"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;